<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ updatingItem.code_name }} - {{ $t("labels.working_time") }}
      <v-spacer></v-spacer>
      <v-btn
        small
        outlined
        color="purple"
        @click="showDialogClone"
        class="mr-2"
      >
        {{ $t("labels.clone") }}
      </v-btn>
      <v-btn small color="error" @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.index") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.shift") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.start") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.end") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.checkin_start") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.checkin_end") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.checkout_start") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.checkout_end") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.cutoff") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.status") }}
              </th>
              <th role="columnheader" class="text-center">
                <v-btn
                  color="success"
                  small
                  @click="addConfig"
                  :disabled="isDisabledBtnAdd"
                >
                  {{ $t("labels.add") }}
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="item.id"
              class="text-center"
            >
              <td>
                <v-text-field
                  v-model.number="item.index"
                  type="number"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.name"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.start"
                  type="time"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.end"
                  type="time"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.checkin_start"
                  type="time"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.checkin_end"
                  type="time"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.checkout_start"
                  type="time"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.checkout_end"
                  type="time"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.cutoff"
                  type="time"
                  :disabled="!item.editing"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td class="text-center vertical-align-middle">
                <div class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="item.active"
                    :disabled="!item.editing"
                    class="mt-0 ml-1"
                    :value="1"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
              <td class="py-1">
                <template v-if="!item.editing">
                  <v-btn
                    x-small
                    color="warning"
                    @click="toggleEditing(item, index, true)"
                    >{{ $t("labels.edit") }}</v-btn
                  >
                </template>
                <template v-else>
                  <v-btn
                    class="mb-1"
                    x-small
                    color="success"
                    @click="updateConfig(item)"
                    >{{ $t("labels.save") }}</v-btn
                  >
                  <br />
                  <v-btn
                    x-small
                    color="error"
                    @click="toggleEditing(item, index, false)"
                    >{{ $t("labels.cancel") }}</v-btn
                  >
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog v-model="cloneDialog" persistent max-width="360px">
      <CloneWarehouseWorkingTime
        :updating-item="updatingItem"
        @refreshData="getList"
        @cancel="hideDialogClone"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "WorkingTime",
  components: {
    CloneWarehouseWorkingTime: () =>
      import("@/components/warehouse/CloneWarehouseWorkingTime"),
  },
  props: {
    updatingItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    items: [],
    filters: {},
    isLoading: false,
    cloneDialog: false,
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    showDialogClone() {
      this.cloneDialog = true;
    },
    hideDialogClone() {
      this.cloneDialog = false;
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        warehouse_id: this.updatingItem.id,
        index: null,
        name: null,
        start: null,
        end: null,
        checkout_start: null,
        checkout_end: null,
        checkin_start: null,
        checkin_end: null,
        cutoff: null,
        active: true,
        editing: true,
      });
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/warehouse/v1/save-working-time", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/warehouse/v1/working-time-list", {
          id_warehouse: this.updatingItem.id,
        })
        .then(({ data }) => {
          this.items = [...data].map((item) => ({
            ...item,
            editing: false,
          }));
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
